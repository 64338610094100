.formWrap{
    margin:  3rem auto;
    
    .loginSocial{
        margin: 1rem auto 0;

        .row{
            margin: 0;
        }
    }
}