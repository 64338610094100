.loginSocial{
    margin: .05rem auto 0;

    .row{
        margin: 0;
    }
}

.link{
    display: block;
    width: 100%;
    margin: 1.5rem auto 0;

    a{
        color: black;
        font-size: 2rem;
    }
}