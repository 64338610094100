.btnLogin {
    display: block;
    width: 100%;
    background: #2c614d;
    font-size: 1.8rem;
    line-height: 1;
    font-weight: 300;
    text-transform: uppercase;
    color: white;
    padding: 1rem 0;
    margin: 0 auto 1rem;
    border: 0;
    outline: none;
}