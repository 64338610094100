.loginAdmin{
    display: block;
    width: 100%;
    max-width: 40.0rem;
    margin: 10rem auto;
}

.link{
    display: block;
    width: 100%;
    margin: 1.5rem auto 0;

    a{
        color: black;
        font-size: 2rem;
    }
}