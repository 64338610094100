.header {
    height: 6.5rem;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
  
    .wrap {
      position: relative;
      height: 100%;
      max-width: 1450px;
      margin: 0 auto;
  
      .logo {
        width: 35.0rem;
        position: absolute;
        top: 50%; left: 10px;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        transform: translateY(-50%);
  
        img {
          display: block;
          width: 100%;
          margin: 0;
        }
      }
  
      nav {
        display: block;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
  
        ul, ul li {
          padding: 0;
          margin: 0;
          height: 100%;
        }
  
        ul {
          text-align: center;
  
          li {
            display: inline-block;
            margin: 0 1rem;
            list-style-type: none;
  
            a {
              font-size: 1.8rem;
              line-height: 6.5rem;
              vertical-align: middle;
              color: black;
              text-decoration: none;
              text-transform: uppercase;
              cursor: pointer;
            }
          }
        }
      }
  
      .chamarAcoes {
        position: absolute;
        top: 50%; right: 10px;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        transform: translateY(-50%);
  
        ul, li {
          margin: 0;
          padding: 0;
        }
  
        li {
          display: inline-block;
          list-style-type: none;
          margin-right: 1.5rem;
  
          &:last-child {
            margin-right: 0;
          }
  
          a, span {
            font-size: 1.8rem;
            line-height: 1;
            color: black;
            text-decoration: none;
            text-transform: uppercase;
            cursor: pointer;
          }
  
        }
  
      }
  
    }
  
  }