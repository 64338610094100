.authWrapper{
    display: block;
    width: 100%;
    max-width: 40.0rem;
    margin: 4rem auto;
    border: 1px solid black;

    .wrap{
        padding: 10px;

        h2 {
            font-size: 2.2rem;
            line-height: 1;
            font-weight: 400;
            text-transform: uppercase;
            text-align: center;
            display: block;
            width: 100%;
            padding: 0;
            margin: 0 auto 3rem;
        }
    }
  

            .row{
                margin: 0;
            }
}